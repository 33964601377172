import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../utils/api';

const Detail = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getEvaluationsPATById = async () => {
      const data = await api.adminGetEvaluatesPATById({ id });
      setData(data);
      setIsloading(false);
    }

    getEvaluationsPATById();
  }, [id]);

  if (isLoading) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="h5">Penilaian Antar Teman</h1>
          <div className="col-2">
          </div>
        </div>
        <div className="row g-3">
          {data?.length < 1 ?
            <div class="alert alert-warning alert-dismissible fade show text-center" role="alert">
              <strong>Teman sedivisi belum menilai!</strong>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            :
            <>
              {data?.map((friend, key) => (
                <div key={key} className="col-12">
                  <div key={key} className="row g-3">

                    <div className="col-12">
                      <label htmlFor="Mentess" className="form-label">Nama</label>
                      <div className="input-group has-validation">
                        <input disabled value={`${friend?.firstName} ${friend?.lastName}`} type="text" className="form-control" id="Mentess" placeholder="Mentess" />
                        <button onClick={() => navigate(`/admin/users/${friend.friendsId}`)} className="btn btn-primary" type="button" id="button-addon2">Detil</button>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Nilai Total" className="form-label">Nilai Total</label>
                      <div className="input-group has-validation">
                        <input disabled value={friend?.total} type="text" className="form-control" id="Nilai Total" placeholder="Nilai Total" />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Rata Rata Nilai" className="form-label">Rata Rata Nilai</label>
                      <div className="input-group has-validation">
                        <input disabled value={friend?.average} type="text" className="form-control" id="Rata Rata Nilai" placeholder="Rata Rata Nilai" />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Catatan" className="form-label">Catatan</label>
                      <div className="input-group has-validation">
                        <textarea rows={3} disabled value={friend?.note} type="text" className="form-control" id="Catatan" placeholder="Catatan" />
                      </div>
                    </div>

                    <div className="col-12 table-responsive">
                      <table className='table table-hover'>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Klasifikasi</th>
                            <th scope="col">Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          {friend?.evaluatedByFriends?.map((value, key) => (
                            <tr key={key} style={{ cursor: 'default' }}>
                              <th scope="row">{key + 1}</th>
                              <td>{value?.classification}</td>
                              <td>{value?.evaluation}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Detail;
