import React from 'react';
import { Image, Text, View, Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import logoBakrieCenter from './assets/bakriecenter.png';
import SinarmasLogo from './assets/sinarmas.png';
import CollaborationLogo from './assets/collaboration.png';
import PartnerLogo from './assets/partner_logo.png';
import SignatureCEO from './assets/signature.png';

const EvaluationDocument = ({ finalEvaluation }) => {
  Font.register({
    family: 'OpenSans',
    fonts: [
      {
        src: `/font/OpenSans-Regular.ttf`
      },
      {
        src: `/font/OpenSans-Bold.ttf`,
        fontWeight: 'bold'
      },
      {
        src: `/font/OpenSans-Italic.ttf`,
        fontWeight: 'normal',
        fontStyle: 'italic'
      },
      {
        src: `/font/OpenSans-BoldItalic.ttf`,
        fontWeight: 'bold',
        fontStyle: 'italic'
      },
    ]
  })

  const styles = StyleSheet.create({
    page: { fontSize: 11, paddingBottom: 40, paddingTop: 40, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },
    spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: '#3E3E3E' },
    spaceEvenly: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', color: '#3E3E3E' },
    titleContainer: { flexDirection: 'row', marginTop: 24 },
    logo: { width: 200 },
    centerVH: { textAlign: 'center', justifyContent: 'center' },
    bold: {
      fontFamily: 'Times-Roman',
      fontWeight: 'bold',
    },
    table: {
      display: 'table',
      width: 'auto',
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 10,
    },
    tableRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    tableColHeader: {
      fontSize: 11,
      fontFamily: 'OpenSans',
      fontWeight: 'bold',
      height: 30,
      textAlign: 'center',
      justifyContent: 'center',
      backgroundColor: '#1366A8',
      color: '#FFF',
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1
    },
    tableColBody: {
      textAlign: 'center',
      height: '100%',
      justifyContent: 'center',
      padding: 5,
      fontSize: 11,
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    tableFinal: {
      backgroundColor: '#1366A8',
      color: '#FFF',
      width: '100%',
      fontSize: 11,
      fontFamily: 'OpenSans',
      fontWeight: 'bold',
    },
    textContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
  });

  const BakrieCenterFoundation = () => (
    <View style={styles.titleContainer}>
      <View style={[styles.spaceBetween, { marginTop: -30, marginBottom: 30 }]}>
        <Image style={styles.logo} src={PartnerLogo} />
        <Image style={styles.logo} src={CollaborationLogo} />
      </View>
    </View>
  )

  const Title = () => (
    <View>
      <Text style={{ fontSize: 14, textAlign: 'center', fontFamily: 'OpenSans', fontWeight: 'bold' }}>Penilaian Akhir</Text>
      <Text style={{ fontSize: 14, textAlign: 'center', fontFamily: 'OpenSans', fontWeight: 'bold' }}>Magang Campus Leaders Program</Text>
      <Text style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, fontFamily: 'OpenSans', fontWeight: 'bold' }}>Implementasi Program Kolaborasi Nasional Multi-stakeholders dan Multi-years</Text>
      <Text style={{ fontSize: 12, textAlign: 'center', fontFamily: 'OpenSans', fontWeight: 'bold' }}>dalam Percepatan Eliminasi TBC di Indonesia Sebelum Tahun 2030</Text>

      <View style={styles.textContainer}>
        <Text style={{ fontSize: 12, fontFamily: 'OpenSans', fontWeight: 'bold' }}>Periode 19 Agustus 2024 - 14 Januari 2025{' '}</Text>
        <Text style={{ fontSize: 12, fontFamily: 'OpenSans', fontStyle: 'italic', fontWeight: 'bold' }}>(Final Assessment)</Text>
      </View>
    </View>
  )

  const Detail = () => (
    <View style={{ flexDirection: 'row', marginTop: 10 }}>
      <View style={styles.spaceEvenly}>
        <View>
          <Text>Nama Lengkap: </Text>
          <Text>Asal Universitas: </Text>
          <Text>Jurusan: </Text>
          <Text>Divisi: </Text>
        </View>
        <View>
          <Text>: </Text>
          <Text>: </Text>
          <Text>: </Text>
          <Text>: </Text>
        </View>
        <View>
          <Text>{finalEvaluation?.mentessFirstName} {finalEvaluation?.mentessLastName}</Text>
          <Text>{finalEvaluation?.universities?.universitiesName}</Text>
          <Text>{finalEvaluation?.universities?.universitiesMajor}</Text>
          <Text>{finalEvaluation?.institutions?.divisionName}</Text>
        </View>
      </View>
    </View>
  )

  const TableHardskill = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[{ width: '20%', borderLeftWidth: 1 }, styles.tableColHeader]}>
          <Text>NO</Text>
        </View>
        <View style={[{ width: '60%' }, styles.tableColHeader]}>
          <Text>Klasifikasi Hardskill</Text>
        </View>
        <View style={[{ width: '40%' }, styles.tableColHeader]}>
          <Text>Nilai Akhir</Text>
        </View>
        <View style={[{ width: '100%' }, styles.tableColHeader]}>
          <Text>Catatan</Text>
        </View>
      </View>
      {finalEvaluation?.evaluationsHS?.map((item, key) => (
        <View style={styles.tableRow} key={item.id}>
          <View style={[{ width: '20%', borderLeftWidth: 1 }, styles.tableColBody]}>
            <Text>{key + 1}</Text>
          </View>
          <View style={[{ width: '60%' }, styles.tableColBody]}>
            <Text style={{ textAlign: 'justify' }}>KPI {key + 1}: {item.classification}</Text>
          </View>
          <View style={[{ width: '40%' }, styles.tableColBody]}>
            <Text>{item.evaluation}</Text>
          </View>
          <View style={[{ width: '100%' }, styles.tableColBody]}>
            <Text style={{ textAlign: 'justify' }}>{item.note}</Text>
          </View>
        </View>
      ))}
    </View >
  )

  const TableSoftskill = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[{ width: '20%', borderLeftWidth: 1 }, styles.tableColHeader]}>
          <Text>NO</Text>
        </View>
        <View style={[{ width: '60%' }, styles.tableColHeader]}>
          <Text>Klasifikasi Softskill</Text>
        </View>
        <View style={[{ width: '40%' }, styles.tableColHeader]}>
          <Text>Nilai Akhir</Text>
        </View>
        <View style={[{ width: '100%' }, styles.tableColHeader]}>
          <Text>Catatan</Text>
        </View>
      </View>
      {finalEvaluation?.evaluationsSS?.map((item, key) => (
        <View style={styles.tableRow} key={item.id}>
          <View style={[{ width: '20%', borderLeftWidth: 1 }, styles.tableColBody]}>
            <Text>{key + 1}</Text>
          </View>
          <View style={[{ width: '60%' }, styles.tableColBody]}>
            <Text style={{ textAlign: 'justify' }}>{item.classification}</Text>
          </View>
          <View style={[{ width: '40%' }, styles.tableColBody]}>
            <Text>{item.evaluation}</Text>
          </View>
          <View style={[{ width: '100%' }, styles.tableColBody]}>
            <Text style={{ textAlign: 'justify' }}>{item.note}</Text>
          </View>
        </View>
      ))}
    </View >
  )

  const TableByBCF = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[{ width: '20%', borderLeftWidth: 1 }, styles.tableColHeader]}>
          <Text>NO</Text>
        </View>
        <View style={[{ width: '60%' }, styles.tableColHeader]}>
          <Text>Klasifikasi BCF</Text>
        </View>
        <View style={[{ width: '40%' }, styles.tableColHeader]}>
          <Text>Nilai Akhir</Text>
        </View>
        <View style={[{ width: '100%' }, styles.tableColHeader]}>
          <Text>Catatan</Text>
        </View>
      </View>
      {finalEvaluation?.evaluationsBCF?.classifications?.map((item, key) => (
        <View style={styles.tableRow} key={item.id}>
          <View style={[{ width: '20%', borderLeftWidth: 1 }, styles.tableColBody]}>
            <Text>{key + 1}</Text>
          </View>
          <View style={[{ width: '60%' }, styles.tableColBody]}>
            <Text style={{ textAlign: 'justify' }}>{item.classification}</Text>
          </View>
          <View style={[{ width: '40%' }, styles.tableColBody]}>
            <Text>{item.evaluation}</Text>
          </View>
          <View style={[{ width: '100%' }, styles.tableColBody]}>
            <Text style={{ textAlign: 'justify' }}>{item.note}</Text>
          </View>
        </View>
      ))}
      <View style={styles.tableRow}>
        <View style={[styles.tableColBody, styles.tableFinal, { borderLeftWidth: 1 }]}>
          <Text>Nilai Total</Text>
        </View>
        <View style={[styles.tableColBody, styles.tableFinal]}>
          <Text>{finalEvaluation?.finalEvaluationTotal}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColBody, styles.tableFinal, { borderLeftWidth: 1 }]}>
          <Text>Nilai Rata-rata</Text>
        </View>
        <View style={[styles.tableColBody, styles.tableFinal]}>
          <Text>{finalEvaluation?.finalEvaluationAverage}</Text>
        </View>
      </View>
    </View >
  )

  const DateSignate = () => (
    <View>
      <Text style={{ fontSize: 12, textAlign: 'center', paddingTop: 20 }}>Jakarta, 14 Januari 2025</Text>
    </View>
  )

  const Signature = () => (
    <View style={styles.titleContainer}>
      <View style={[styles.spaceBetween]}>
        <Text></Text>
        <Image style={{ width: 200 }} src={SignatureCEO} />
        <Text></Text>
      </View>
    </View>
  )

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <BakrieCenterFoundation />
        <Title />
        <Detail />
        <TableHardskill />
        <TableSoftskill />
        <TableByBCF />
        <DateSignate />
        <Signature />
      </Page>
    </Document>
  )
}

export default EvaluationDocument;
