import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const InstitutionsList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    const getInstitutions = async () => {
      const data = await api.adminGetInstitutions({ filterBy: '' });
      setInstitutions(data);
      setIsloading(false)
    }

    getInstitutions();
  }, []);

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Lembaga</h1>
          {/* <div className="col-2">
            <div className="d-grid gap-2">
              <Link to='/admin/mentors/add' className='btn btn-primary'>Tambah</Link>
            </div>
          </div> */}
        </div>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Penempatan</th>
              <th scope="col">Total Mahasiswa</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ?
              <>
                {Array.apply(null, Array(Number(10))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {institutions?.map((institution, key) => (
                  <tr key={key} onClick={() => navigate(`/admin/institutions/${institution.institutionsId}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{key + 1}</th>
                    <td>{institution.institutionsName}</td>
                    <td>{institution.institutionsLocation}</td>
                    <td>{institution.totalUsers}</td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default InstitutionsList;
