import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

const UsersList = ({ authUser }) => {
  const { roleName } = authUser?.currentlyProgram.role || {};
  const { batchId: batchdIdLoggedIn } = authUser?.currentlyProgram?.batch || {};
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage] = useState(searchParams.get("page") || "1");
  const [pageSize] = useState(searchParams.get("pageSize") || "25");

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [batchId, setBatchId] = useState(searchParams.get("batch") || "2");

  useEffect(() => {
    if (!searchParams.has('pageSize')) {
      setSearchParams({ ...searchParams, pageSize });
      navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`);
    }

    const getAllUsers = async () => {
      setLoading(true);
      const data = await api.adminGetAllUsers({ batchId, page: currentPage, pageSize });
      setUsers(data);
      setLoading(false);
    }

    getAllUsers();
  }, [pageSize, currentPage, searchParams, setSearchParams, navigate, batchId]);

  const handleNavigateToPage = (pageNumber) => {
    setSearchParams({ ...searchParams, page: pageNumber });
    navigate(`?batch=${batchId}&page=${pageNumber}&pageSize=${pageSize}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize: pageSize });
    navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`)
  }

  const handleBatchId = (batchId) => {
    setSearchParams({ ...searchParams, batch: batchId });
    navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`)
  }

  const onHandleNavigateDetailUsers = ({ CLPUsersId }) => {
    if (roleName === 'Admin') {
      navigate(`/admin/users/${CLPUsersId}`)
    }
    return;
  }

  const onAssignStudentsToMentors = async ({ id }) => {
    const { status, message } = await api.mentorAssignStudentsEvaluate({
      id,
      batchId: batchdIdLoggedIn,
    });
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate('/mentor/evaluation')
    }
  }

  return (
    <div className="card">
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Mahasiswa</h1>
          <div className="row">
            <div className="col-6">
              <select value={pageSize} onChange={(e) => handlePageSize(e.target.value)} class="form-select" aria-label="Default select example">
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="col-6">
              <select value={batchId} onChange={(e) => handleBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                <option value={1}>BATCH: 8</option>
                <option value={2}>BATCH: 9</option>
              </select>
            </div>
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Lembaga</th>
              <th scope="col">Penempatan</th>
              <th scope="col">Universitas</th>
              <th scope="col">Kategori</th>
              {roleName === "Mentor" &&
                <th scope="col">Aksi</th>
              }
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {Array.apply(null, Array(Number(pageSize))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    {roleName === "Mentor" &&
                      <td><span className='placeholder col-12'></span></td>
                    }
                  </tr>
                ))}
              </>
              :
              <>
                {users?.users?.map((value, key) => (
                  <tr key={key}
                    onClick={() => onHandleNavigateDetailUsers({ CLPUsersId: value.CLPUsersId })}
                    style={{ cursor: roleName === 'Mentor' ? 'default' : 'pointer' }}
                  >
                    <th scope="row">{(currentPage - 1) * pageSize + (key + 1)}</th>
                    <td>{value.firstName} {value.lastName}</td>
                    <td>{value.institutions.institutionsName}</td>
                    <td>{value.institutions.institutionsLocation}</td>
                    <td>{value.universities.universitiesName}</td>
                    <td>{value.program.thirdTierProgramName}</td>
                    {roleName === "Mentor" &&
                      <td>
                        <button onClick={(e) => onAssignStudentsToMentors({ id: value.CLPUsersId })} className='btn btn-primary badge rounded-pill'>Tambah</button>
                      </td>
                    }
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(users.previous)} class={`page-link ${!users.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{users.current + 1 || parseInt(currentPage)}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(users.next + 1)} class={`page-link ${!users.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div >
  )
}

export default UsersList