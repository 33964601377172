import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const EvaluationSSAdd = () => {
  const location = useLocation();
  const { data } = location.state;
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [mentess, setMentess] = useState([]);

  useEffect(() => {
    const getEvaluationsById = async () => {
      const data = await api.adminGeEvaluationsFinalSS({ id });
      setMentess(data);
      setIsPreLoading(false)
    }

    getEvaluationsById();
  }, [id]);

  const onHandleChangeNilaiSoftskill = ({ evaluation, key }) => {
    if (evaluation > 100) { alert('Nilai tidak boleh lebih dari 100!'); return }
    const newState = [...mentess];
    newState[key].evaluation = parseInt(evaluation);
    setMentess(newState);
    if (newState[key].evaluation < 1 || newState[key].evaluation < 1) {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandleChangeCatatanSoftskill = ({ note, key }) => {
    const newState = [...mentess];
    newState[key].note = note;
    setMentess(newState);
  }

  const onHandlePostEvaluate = async () => {
    setIsloading(true);
    const payload = {
      finalEvaluationsId: parseInt(id),
      evaluates: mentess,
    };
    await api.mentorsUpdateEvaluationsFinalSS({ payload });
    setTimeout(() => {
      alert('Berhasil dinilai!');
      navigate(`/mentor/evaluation/${id}`);
      setIsloading(false);
    }, 1000);
  }

  return (
    <div className='row g-4'>

      <div className="col-12 col-md-8">
        {isPreLoading ?
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          :
          <div className="card">

            <div className="card-body">
              <h2 className="h5">Softskill</h2>
              {mentess?.map((value, key) => (
                <div className="row g-3 mb-3">
                  <div className="col-8">
                    <label htmlFor="Klasifikasi" className="form-label">KPI {key + 1}:</label>
                    <input disabled value={value.classification} type="text" className="form-control" id="Klasifikasi" />
                  </div>

                  <div className="col-4">
                    <label htmlFor="Nilai" className="form-label">Nilai (1 - 100)</label>
                    <input value={value.evaluation} onChange={(e) => onHandleChangeNilaiSoftskill({ evaluation: e.target.value, key })} type="number" placeholder='0' className="form-control" id="Nilai" min={1} max={100} required />
                  </div>

                  <div className="col-12">
                    <label htmlFor="Catatan" className="form-label">Catatan</label>
                    <textarea rows={2} value={value.note} onChange={(e) => onHandleChangeCatatanSoftskill({ note: e.target.value, key })} type="text" className="form-control" id="Catatan" required />
                  </div>
                </div>
              ))}

              <div className="d-grid gap-2">
                <button disabled={disabled || isLoading} className='btn btn-primary' type='button' onClick={() => onHandlePostEvaluate()}>
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Simpan</span>
                    </>
                  }
                </button>
              </div>

            </div>
          </div>
        }
      </div>

      <div className="col-12 col-md-4 order-md-last order-first">
        <div className="row g-3 sticky-md-top" style={{ zIndex: 99 }}>
          <div className="card">
            <div className='card-body'>
              <h1 className="h5">Profil</h1>
              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="mentess" className="form-label">Mentess</label>
                  <input disabled value={`${data?.mentessFirstName} ${data?.mentessLastName}`} type="text" className="form-control" id="mentess" placeholder="Mentess" required />
                </div>
                <div className="col-12">
                  <label htmlFor="divisi" className="form-label">Divisi</label>
                  <input disabled value={data?.institutions?.divisionName} type="text" className="form-control" id="divisi" placeholder="Divisi" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Jurusan</label>
                  <input disabled value={data?.universities?.universitiesMajor} type="text" className="form-control" id="jurusan" placeholder="Jurusan" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Universitas</label>
                  <input disabled value={data?.universities?.universitiesName} type="text" className="form-control" id="universitas" placeholder="Universitas" required />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default EvaluationSSAdd;
