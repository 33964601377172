import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../utils/api';

const ClassificationsHardskills = () => {
  const [loading, setLoading] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [classifications, setClassifications] = useState([]);

  useEffect(() => {
    const getInstitutions = async () => {
      const data = await api.adminGetInstitutions({ filterBy: '' });
      setInstitutions(data);
    }

    const getClassifications = async () => {
      setLoading(true);
      const data = await api.adminGetClassficationsHardskill({ pageSize: 25, page: 1 });
      setClassifications(data);
      setLoading(false);
    }

    getInstitutions();
    getClassifications();
  }, []);

  const onHandleDivisiByInstitutionId = async ({ id }) => {
    const data = await api.adminGetInstitutionsById({ id, batchId: 2 });
    setDivisions(data.divisions);
  }

  const onHandleGetClassificationsByDivisiLembagaId = async ({ id }) => {
    setLoading(true);
    const data = await api.adminGetClassficationsHardskillByDivisiLembagaId({ id });
    setClassifications(data);
    setLoading(false);
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>KPI</h1>
          <div className="col-2">
            <div className="d-grid gap-2">
              <Link to='/admin/classifications/add' className='btn btn-primary'>Tambah</Link>
            </div>
          </div>
        </div>

        <div className="row g-3 mb-3">

          <div className="col-6">
            <label htmlFor="country" className="form-label">Lembaga</label>
            <select onChange={(e) => onHandleDivisiByInstitutionId({ id: e.target.value })} className="form-select" required>
              <option defaultValue>Pilih Lembaga</option>
              {institutions.map((institution, key) => (
                <option key={key} value={institution.institutionsId}>{institution.institutionsName}</option>
              ))}
            </select>
          </div>

          <div className="col-6">
            <label htmlFor="country" className="form-label">Divisi</label>
            <select disabled={divisions?.length <= 0} onChange={(e) => onHandleGetClassificationsByDivisiLembagaId({ id: e.target.value })} className="form-select" required>
              <option defaultValue>Pilih Divisi</option>
              {divisions?.map((division, key) => (
                <option key={key} value={division.institutionsDivisionId}>
                  {division.divisionName}
                  {' | Batch: '}
                  {division?.batch.map((value, key) => (
                    <span key={key}>{` ${value.batchId === 1 ? 8 : 9}, `}</span>
                  ))}
                </option>
              ))}
            </select>
          </div>

        </div>

        {loading ?
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          :
          <>
            {classifications?.hardskill?.length < 1 ?
              <div class="alert alert-warning alert-dismissible fade show text-center" role="alert">
                <strong>KPI belum terisi!</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              :
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Lembaga</th>
                    <th scope="col">Divisi</th>
                    <th scope="col">KPI</th>
                    <th scope="col">BATCH</th>
                  </tr>
                </thead>
                <tbody>
                  {classifications?.hardskill?.map((classification, key) => (
                    <tr key={key}>
                      <th scope="row">{key + 1}</th>
                      <td>{classification.institutions.institutionsName}</td>
                      <td>{classification.institutions.divisionName}</td>
                      <td>{classification.classification}</td>
                      <td>{classification.batchId === 1 ? "8" : "9"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </>
        }
      </div>
    </div>
  );
};

export default ClassificationsHardskills;
