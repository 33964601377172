import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = ({ authUser, setAuthUser, signOut }) => {
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const slug = pathname[1];

  const onHandleChangeBatch = ({ batch }) => {
    const newAuthUsers = { ...authUser }
    newAuthUsers.currentlyProgram.batch = batch;
    setAuthUser(newAuthUsers);
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light shadow-sm">
        <div className="container p-2">
          <Link className="navbar-brand" to='/'>
            <img src="/logo192.png" style={{ height: 50, objectFit: 'scale-down' }} className='img-fluid' alt='ngedate.id' />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className={`nav-link ${slug === 'publication' && 'active'}`} aria-current="page" to="/publication">Publikasi</Link>
              </li>
            </ul>

            {!authUser ?
              <div className="text-end">
                <Link to='/app/login' type="button" className="btn btn-primary">Sign in</Link>
              </div>
              :
              <>
                {authUser?.currentlyProgram.role.roleName === 'Admin' &&
                  <div className="text-end">
                    <p>LoggedIn As:{' '}
                      <Link to='/admin'>
                        <b>Admin</b>
                      </Link>
                    </p>
                  </div>
                }
                {authUser?.currentlyProgram?.role.roleName === 'Mentor' &&
                  <div className="text-end">
                    <p>LoggedIn As:{' '}
                      <Link to='/mentor'>
                        <b>Mentors</b>
                      </Link>
                    </p>
                  </div>
                }
                {authUser?.currentlyProgram?.role.roleName === 'Mahasiswa' &&
                  <>
                    <div className="flex-shrink-0 dropdown">
                      <a href="#/" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>{authUser.firstName} {authUser.lastName}</span>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-lg-end text-small shadow">
                        <li><Link className="dropdown-item" to={`/app`}>Dashboard</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to={`/app/publication`}>Publikasi</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to={`/app/evaluation`}>
                          Penilaian (PAT) {' '}
                          <span class="badge text-bg-success">New</span></Link>
                        </li>
                        <li><Link className="dropdown-item" to={`/app/evaluation/final`}>Penilaian (FINAL)</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to={`/app/profile`}>Profil</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a onClick={signOut} className="dropdown-item" href="#/">Sign out</a></li>
                      </ul>
                    </div>
                    <div className="vr" style={{ marginLeft: 10, marginRight: 10 }}></div>
                    <div className="flex-shrink-0 dropdown">
                      <a href="#/" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>BATCH</span>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-lg-end text-small shadow">
                        {authUser?.offeredProgram?.map((value, key) => (
                          <li key={key} onClick={() => onHandleChangeBatch({ batch: value.batch })}>
                            <a className={`dropdown-item ${authUser.currentlyProgram.batch.batchId === value.batchId && 'active'}`} href='#/'>BATCH: {value.batch.batch}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                }
              </>
            }

          </div>
        </div>
      </nav>

      {/* <div class="alert alert-warning text-center" role="alert">
        <h4 class="alert-heading">Server maintenance!</h4>
        Haii, teman-teman. Sistem akan berjalan normal pada hari Kamis, 28 November 2024.
      </div> */}
    </>
  )
}

export default Navigation;
