import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EvaluationList = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage] = useState(searchParams.get("page") || 1);
  const [itemsPerPage] = useState(searchParams.get("pageSize") || 25)

  const [loading, setLoading] = useState(true);
  const [evaluates, setEvaluates] = useState([]);
  const [batchId, setBatchId] = useState(searchParams.get("batch") || "1");

  useEffect(() => {
    if (!searchParams.has('pageSize')) {
      setSearchParams({ ...searchParams, itemsPerPage });
      navigate(`?page=${currentPage}&pageSize=${itemsPerPage}`);
    }

    const getAllEvaluates = async () => {
      setLoading(true);
      const data = await api.adminGetAllEvaluates({ batchId, page: currentPage, pageSize: itemsPerPage });
      setEvaluates(data);
      setLoading(false);
    }

    getAllEvaluates();
  }, [currentPage, itemsPerPage, navigate, searchParams, setSearchParams, batchId]);

  const handleNavigateToPage = (pageNumber) => {
    setSearchParams({ ...searchParams, page: pageNumber });
    navigate(`?batch=${batchId}&page=${pageNumber}&pageSize=${itemsPerPage}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize: pageSize });
    navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`)
  }

  const handleBatchId = (batchId) => {
    setSearchParams({ ...searchParams, batch: batchId });
    navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${itemsPerPage}`)
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between">
          <h1 className='h5'>Mahasiswa</h1>
          <div className="row">
            <div className="col-6">
              <select value={itemsPerPage} onChange={(e) => handlePageSize(e.target.value)} className="form-select" aria-label="Default select example">
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="col-6">
              <select value={batchId} onChange={(e) => handleBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                <option value={1}>BATCH: 8</option>
                <option value={2}>BATCH: 9</option>
              </select>
            </div>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Mentors</th>
              <th scope="col">Mentess</th>
              <th scope="col">Jurusan</th>
              <th scope="col">Universitas</th>
              <th scope="col">Divisi</th>
              <th scope="col">Nilai</th>
              <th scope="col">PAT</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {Array.apply(null, Array(Number(itemsPerPage))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {evaluates?.users?.map((evaluate, key) => (
                  <tr key={key} onClick={() => navigate(`/admin/evaluation/${evaluate.CLPUsersId}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{(currentPage - 1) * itemsPerPage + (key + 1)}</th>
                    <td>{evaluate.mentors.firstName} {evaluate.mentors.lastName}</td>
                    <td>{evaluate.firstName} {evaluate.lastName}</td>
                    <td>{evaluate.universities.universitiesMajor}</td>
                    <td>{evaluate.universities.universitiesName}</td>
                    <td>{evaluate.institutions.institutionsDivision}</td>
                    <td>{evaluate?.evaluationFinal?.finalEvaluationAverage}</td>
                    <td width={`${15}%`} >
                      <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={evaluate.progressPAT.progress} aria-valuemin="0" aria-valuemax="100">
                        <div class={`progress-bar overflow-visible ${evaluate.progressPAT.progress < 10 && 'text-dark'}`} style={{ width: evaluate.progressPAT.progress + '%' }}>{`${evaluate.progressPAT.hasEvaluated}/${evaluate.progressPAT.totalUsersDivision}`}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(evaluates.previous)} class={`page-link ${!evaluates.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{evaluates.current + 1 || parseInt(currentPage)}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(evaluates.next + 1)} class={`page-link ${!evaluates.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default EvaluationList