import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginAction } from '../../utils/action';
import ToastNotifications from '../../Components/ToastNotifications';
import api from '../../utils/api';

const Login = ({ setAuthUser }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [messageNotifications, setMessage] = useState('');
  const [show, setShow] = useState(false);

  const onHandleLogin = async () => {
    const { status, message, data: authUsers } = await loginAction({ email, password })
    if (status === 'success') {
      const offeredProgram = await api.getOwnOfferedProgram();
      const data = {
        ...authUsers,
        offeredProgram,
      }
      setAuthUser(data);
      switch (data.currentlyProgram.role.roleName) {
        case 'Mentor':
          navigate('/mentor');
          break;
        default:
          navigate('/app');
          break;
      }
    } else {
      setMessage(message);
      setShow(true);
    }
  };

  return (
    <>
      <div class="row apgn-items-center g-lg-5 py-5">
        <div class="col-lg-7 text-center text-lg-start">
          <h1 class="display-4 fw-bold lh-1 text-body-emphasis mb-3">Masuk</h1>
          <p class="col-lg-10 fs-5">Kamu bisa masuk menggunakan akun yang pernah mendaftarkan program:</p>
          <p>CLP (Campus Leaders Program)</p>
          <p>LEAD (Leadership Experience Development)</p>
          <p>HOL (Home Of Leaders)</p>
        </div>
        <div class="col-md-10 mx-auto col-lg-5">
          <form class="p-4 p-md-5 border rounded-3 bg-body-tertiary">
            <div class="form-floating mb-3">
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
              <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating mb-3">
              <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" id="floatingPassword" placeholder="Password" />
              <label for="floatingPassword">Password</label>
            </div>
            {/* <div class="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me" /> Remember me
            </label>
          </div> */}
            <button onClick={() => onHandleLogin()} class="w-100 btn btn-lg btn-primary" type="button">Masuk</button>
            <hr class="my-4" />
            <small class="text-body-secondary">Kamu Lupa <Link to="/forget-password">Password?</Link></small>
          </form>
        </div>
      </div>
      <ToastNotifications setShowNotifications={setShow} show={show} message={messageNotifications} />
    </>
  )
}

export default Login;
