import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';

const Detail = () => {
  const { id } = useParams();
  const [institutions, setInstitutions] = useState();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const getInstitutionsById = async () => {
      const data = await api.adminGetInstitutionsById({ id, batchId: 2 });
      setInstitutions(data);
      setIsloading(false);

    }

    getInstitutionsById();
  }, [id]);

  if (isLoading) {
    return <>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="h5">Lembaga</h1>
          <div className="col-2">
            {/* <div className="d-grid gap-2">
                <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button>
              </div> */}
          </div>
        </div>

        <div className="row g-3">
          <div className="col-12">
            <label htmlFor="Nama" className="form-label">Nama</label>
            <div className="input-group has-validation">
              <input disabled value={institutions?.institutionsName} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Nama" className="form-label">Lokasi</label>
            <div className="input-group has-validation">
              <input disabled value={institutions?.institutionsLocation} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          {institutions?.divisions?.map((value, key) => (
            <div key={key} className="col-12">
              <div key={key} className="row g-3">
                <div className="col-12">
                  <label htmlFor="Divisi" className="form-label">Divisi</label>
                  <div className="input-group has-validation">
                    <input disabled value={value?.divisionName} type="text" className="form-control" />
                  </div>
                </div>

                <div className="col-12 table-responsive">
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nama</th>
                        <th scope="col">Universitas</th>
                        <th scope="col">Semester</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value?.students?.map((value, key) => (
                        <tr key={key} onClick={() => navigate(`/admin/users/${value.CLPUsersId}`)} style={{ cursor: 'pointer' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{`${value.firstName} ${value.lastName}`}</td>
                          <td>{value.universitiesName}</td>
                          <td>{value.numberPhone}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}

        </div>

      </div>
    </div>
  )
}

export default Detail;
