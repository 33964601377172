import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../utils/api';

const Detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getEvaluationsPATById = async () => {
      const data = await api.adminGetHasEvaluatedPATById({ id });
      setData(data);
      setRefresh(false);
      setIsloading(false);
    }

    getEvaluationsPATById();
  }, [id, refresh]);

  const onHandleDeleteEvaluated = async ({ firstName, lastName, evaluationsFinalPATId, friendsId }) => {
    const confirm = window.confirm(`Yakin ingin menghapus ${firstName} ${lastName}?`);
    if (confirm) {
      const payload = {
        usersId: id,
        friendsId
      };
      const message = await api.adminDeleteHasEvaluatedPATById({ id: evaluationsFinalPATId, payload });
      alert(message);
      setRefresh(true);
    }
  }

  if (isLoading) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      <div className='card-body'>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="h5">Penilaian Antar Teman</h1>
          <div className="col-2">
            {/* <div className="d-grid gap-2">
                <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button>
              </div> */}
          </div>
        </div>
        <div className="row g-3">
          {data?.hasEvaluatedToFriends?.length < 1 ?
            <div class="alert alert-warning alert-dismissible fade show text-center" role="alert">
              <strong>Belum menilai!</strong>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            :
            <>
              <div className="col-12">
                <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={data?.progress} aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar" style={{ width: data?.progress + '%' }}>{data?.progress}%</div>
                </div>
              </div>

              <div className="col-6">
                <label htmlFor="Nama" className="form-label">Sudah dinilai</label>
                <div className="input-group has-validation">
                  <input disabled value={data?.hasEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" required />
                </div>
              </div>

              <div className="col-6">
                <label htmlFor="Nama" className="form-label">Belum dinilai</label>
                <div className="input-group has-validation">
                  <input disabled value={data?.notEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" required />
                </div>
              </div>

              <hr />

              {data?.hasEvaluatedToFriends?.map((friend, key) => (
                <div key={key} className="col-12">
                  <div key={key} className="row g-3">

                    <div className="d-flex justify-content-end">
                      <div className="col-2">
                        <div className="d-grid gap-2">
                          <button onClick={() => onHandleDeleteEvaluated({
                            firstName: friend.firstName,
                            lastName: friend.lastName,
                            evaluationsFinalPATId: friend.evaluationsFinalPATId,
                            friendsId: friend.friendsId,
                          })} className='btn btn-danger'>Hapus</button>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Mentess" className="form-label">Nama</label>
                      <div className="input-group has-validation">
                        <input disabled value={`${friend?.firstName} ${friend?.lastName}`} type="text" className="form-control" id="Mentess" placeholder="Mentess" />
                        <button onClick={() => navigate(`/admin/users/${friend.friendsId}`)} className="btn btn-primary" type="button" id="button-addon2">Detil</button>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Nilai Total" className="form-label">Nilai Total</label>
                      <div className="input-group has-validation">
                        <input disabled value={friend?.total} type="text" className="form-control" id="Nilai Total" placeholder="Nilai Total" />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Rata Rata Nilai" className="form-label">Rata Rata Nilai</label>
                      <div className="input-group has-validation">
                        <input disabled value={friend?.average} type="text" className="form-control" id="Rata Rata Nilai" placeholder="Rata Rata Nilai" />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="Catatan" className="form-label">Catatan</label>
                      <div className="input-group has-validation">
                        <textarea rows={4} disabled value={friend?.note} type="text" className="form-control" id="Catatan" placeholder="Catatan" />
                      </div>
                    </div>

                    <div className="col-12 table-responsive">
                      <table className='table table-hover'>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Klasifikasi</th>
                            <th scope="col">Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          {friend?.evaluatedByFriends?.map((value, key) => (
                            <tr key={key} onClick={() => navigate(`/admin/users/${value.id}`)} style={{ cursor: 'pointer' }}>
                              <th scope="row">{key + 1}</th>
                              <td>{value.classification}</td>
                              <td>{value.evaluation}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </>
          }
        </div>
      </div>

    </div>
  )
}

export default Detail;
