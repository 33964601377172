import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { evaluationsKpiBCF } from '../../../utils/data';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const EvaluationAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const { data } = location.state;

  const [isLoading, setIsloading] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [evaluates, setEvaluates] = useState(evaluationsKpiBCF);
  const [hasPAT, setHasPAT] = useState(true);

  useEffect(() => {
    const getClassificationsById = async () => {
      const { classifications, hasPAT } = await api.adminGetClassficationsBCFByUsersId({ id });
      classifications.forEach((e) => {
        if (!e.evaluation < 1) {
          setDisabled(false)
        }
      });
      setEvaluates(classifications);
      setHasPAT(hasPAT);
      setIsPreLoading(false);
    }

    getClassificationsById();
  }, []);

  const onHandleChangeNilai = ({ evaluation, key }) => {
    if (evaluation > 100) { alert('Nilai tidak boleh lebih dari 100!'); return }
    const newState = [...evaluates];
    newState[key].evaluation = parseInt(evaluation);
    setEvaluates(newState);
    if (newState[key].evaluation < 1 || newState[4].evaluation < 1) {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandleChangeCatatan = ({ note, key }) => {
    const newState = [...evaluates];
    newState[key].note = note;
    setEvaluates(newState);
  }

  const onHandlePostEvaluate = async () => {
    setIsloading(true);
    const payload = {
      finalEvaluationsId: id,
      evaluates
    };
    const message = await api.adminUpdateEvaluate({ id, payload });
    setTimeout(() => {
      alert(message);
      setIsloading(false);
      navigate(`/admin/evaluation/${id}`);
    }, 1000);
  }

  return (
    <div className='row g-3'>

      <div className="col-12 col-md-8">
        {isPreLoading ?
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          :
          <div className="card">
            <div className="card-body">
              <h1 className="h5">Nilai</h1>
              <div className="row g-3">

                {evaluates?.map((value, key) => (
                  <div className="col-12">
                    <div className="row g-3">
                      <div className="col-6">
                        <label htmlFor="Klasifikasi" className="form-label">KPI {key + 1} {' '}
                          {hasPAT ? <> {key === 2 && <span class="badge rounded-pill text-bg-info">Dinilai otomatis oleh Sistem PAT!</span>} </> : <>{key === 2 && <span class="badge rounded-pill text-bg-warning">Dinilai manual oleh Admin BCF!</span>}</>}
                        </label>
                        <input disabled value={value.classification} type="text" className="form-control" id="Klasifikasi" />
                      </div>

                      <div className="col-6">
                        <label htmlFor="Nilai" className="form-label">Nilai</label>
                        <input disabled={hasPAT && evaluates[key].classification === 'Penilaian Antar Teman'} value={evaluates[key].evaluation} onChange={(e) => onHandleChangeNilai({ evaluation: e.target.value, key })} type="number" placeholder='0' className="form-control" id="Nilai" min={1} max={100} required />
                      </div>

                      <div className="col-12">
                        <label htmlFor="Catatan" className="form-label">Catatan</label>
                        <textarea disabled={hasPAT && evaluates[key].classification === 'Penilaian Antar Teman'} rows={3} value={evaluates[key].note} onChange={(e) => onHandleChangeCatatan({ note: e.target.value, key })} type="text" className="form-control" id="Catatan" required />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-grid gap-2">
                  <button disabled={isLoading || disabled} className='btn btn-primary' type='button' onClick={() => onHandlePostEvaluate()}>
                    {isLoading ?
                      <>
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status"> Loading...</span>
                      </>
                      :
                      <>
                        <span>Simpan</span>
                      </>
                    }
                  </button>
                </div>

              </div>
            </div>
          </div>
        }
      </div>

      <div className="col-12 col-md-4 order-md-last order-first">
        <div className="row g-3 sticky-md-top" style={{ zIndex: 99 }}>
          <div className="card">
            <div className='card-body'>
              <h1 className="h5">Profil</h1>

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="mentess" className="form-label">Mentess</label>
                  <input disabled value={`${data?.mentessFirstName} ${data?.mentessLastName}`} type="text" className="form-control" id="mentess" placeholder="Mentess" required />
                </div>
                <div className="col-12">
                  <label htmlFor="divisi" className="form-label">Divisi</label>
                  <input disabled value={data?.institutions?.divisionName} type="text" className="form-control" id="divisi" placeholder="Divisi" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Jurusan</label>
                  <input disabled value={data?.universities?.universitiesMajor} type="text" className="form-control" id="jurusan" placeholder="Jurusan" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Universitas</label>
                  <input disabled value={data?.universities?.universitiesName} type="text" className="form-control" id="universitas" placeholder="Universitas" required />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default EvaluationAdd;
