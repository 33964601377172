import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';

const MentorsAdd = () => {
  const navigate = useNavigate();
  const [batchId, setBatchId] = useState(2);
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState({
    batchId,
    nama: '',
    email: '',
    jabatan: '',
    keahlian: '',
    divisionInstitutionsId: [],
  });
  const [institutions, setInstitutions] = useState([]);
  const [divisionHasStudents, setDivisionHasStudents] = useState([]);
  const [mentoring, setMentoring] = useState([
    {}
  ]);

  useEffect(() => {
    const getInstitutions = async () => {
      setIsloading(true);
      const data = await api.adminGetInstitutions({ batchId, filterBy: '' });
      setInstitutions(data);
      setDivisionHasStudents([]);
      setIsloading(false);
    }

    getInstitutions();
  }, [batchId]);

  const onHandleDivisiByInstitutionId = async ({ id, key }) => {
    const data = await api.adminGetInstitutionsById({ id, batchId, filterBy: '' });
    const newState = [...mentoring];
    newState[key].institutionId = id;
    newState[key].divisions = data.divisions;
    setMentoring(newState);
  }

  const onHandleDivisiId = ({ value, key }) => {
    const { divisionKey, id } = JSON.parse(value) || {};
    if (divisionKey === null || id === null) return;
    const newState = { ...data };
    newState.divisionInstitutionsId[key] = id;
    setData(newState);

    const newdivisionHasStudents = [...divisionHasStudents];
    newdivisionHasStudents[key] = mentoring[key].divisions[divisionKey].students;
    setDivisionHasStudents(newdivisionHasStudents);
  }

  const onHandlePostMentors = async () => {
    setIsloading(true);
    console.log(data);
    setTimeout(async () => {
      const message = await api.adminAddMentors({ payload: data });
      setIsloading(false);
      alert(message);
      setData({
        nama: '',
        email: '',
        jabatan: '',
        keahlian: '',
        divisiLembagaId: [],
      });
      setMentoring([
        {},
      ]);
      navigate('/admin/mentors')
    }, 1000);
    setTimeout(() => {
      setIsloading(false)
    }, 1000);
  }

  return (
    <div className='row'>
      <div className="col-12 col-md-6 mb-3">
        <div className="shadow-sm">
          <div className="card-body p-3">
            <h1 className='h5'>Mentor</h1>
            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="nama" className="form-label">Nama</label>
                <input value={data.fullName} onChange={(e) => setData({ ...data, fullName: e.target.value })} type="text" className="form-control" required />
              </div>

              <div className="col-12">
                <label htmlFor="nama" className="form-label">Email</label>
                <input value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} type="text" className="form-control" id="nama" required />
              </div>

              <div className="col-12">
                <label htmlFor="jabatan" className="form-label">Jabatan</label>
                <input value={data.position} onChange={(e) => setData({ ...data, position: e.target.value })} type="text" className="form-control" id="jabatan" required />
              </div>

              <div className="col-12">
                <label htmlFor="keahlian" className="form-label">Keahlian</label>
                <input value={data.skill} onChange={(e) => setData({ ...data, skill: e.target.value })} type="text" className="form-control" id="keahlian" required />
              </div>

              <div className="col-12">
                <div className="d-grid gap-2">
                  <button disabled={mentoring.length < 1 || isLoading || data.divisionInstitutionsId.length < 1} onClick={() => onHandlePostMentors()} className='btn btn-primary'>
                    {isLoading ?
                      <>
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status"> Loading...</span>
                      </>
                      :
                      <>
                        <span>Simpan</span>
                      </>
                    }
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="shadow-sm">
          <div className="card-body p-3">
            <div className="d-flex justify-content-between mb-3">
              <h1 className='h5'>Mentoring</h1>
              <div className="col-6">
                <select value={batchId} onChange={(e) => setBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                  <option value={1}>BATCH: 8</option>
                  <option value={2}>BATCH: 9</option>
                  <option value={3}>BATCH: 10</option>
                </select>
              </div>
            </div>
            {mentoring?.map((e, key) => (
              <div className="row g-3">

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Lembaga</label>
                  <select disabled={isLoading} onChange={(e) => onHandleDivisiByInstitutionId({ id: e.target.value, key })} className="form-select" required>
                    <option defaultValue>Pilih Lembaga</option>
                    {institutions.map((value, key) => (
                      <option key={key} value={value.institutionsId}>{value.institutionsName}</option>
                    ))}
                  </select>
                </div>

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Divisi</label>
                  <select disabled={!mentoring[key]?.divisions?.length > 0} onChange={(e) => onHandleDivisiId({ value: e.target.value, key })} className="form-select" required>
                    <option value={JSON.stringify({ divisionKey: null, id: null })} defaultValue>Pilih Divisi</option>
                    {mentoring[key]?.divisions?.map((value, key) => (
                      <option key={key} value={JSON.stringify({ divisionKey: key, id: value.institutionsDivisionId })}>{value.divisionName}</option>
                    ))}
                  </select>
                </div>

                <div key={key} className="col-12">
                  <div className="col-12 table-responsive">
                    <table className='table table-hover'>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nama</th>
                          <th scope="col">Universitas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {divisionHasStudents[key]?.map((value, key) => (
                          <tr key={key} onClick={() => navigate(`/admin/users/${value.CLPUsersId}`)} style={{ cursor: 'pointer' }}>
                            <th scope="row">{key + 1}</th>
                            <td>{`${value.firstName} ${value.lastName}`}</td>
                            <td>{value.universitiesName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            ))}

            <div className="d-grid gap-2 mt-3">
              <button disabled={mentoring.length > 4 || isLoading} onClick={() => setMentoring([...mentoring, {
                institutionId: 388,
                divisions: []
              }])} className='btn btn-primary'>
                {isLoading ?
                  <>
                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span role="status"> Loading...</span>
                  </>
                  :
                  <>
                    <span>Tambah</span>
                  </>
                }
              </button>
            </div>

          </div>
        </div>
      </div>
    </div >
  )
}

export default MentorsAdd;
