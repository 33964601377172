import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../utils/api';

const EvaluationDetail = ({ authUser }) => {
  const { batchId = 1 } = authUser?.currentlyProgram.batch || {};
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getEvaluationsById = async () => {
      const { status, message, data } = await api.adminGetEvaluatesById({ id });
      if (status !== 'success') {
        alert(message);
        navigate(-1);
      }
      setData(data);
      setIsLoading(false);
    }

    getEvaluationsById();
  }, [id]);

  const onHandleDeleteStudents = async () => {
    const { status, message } = await api.mentorsDeleteStudentsEvaluate({ id, batchId })
    if (status !== 'success') {
      alert(message);
    } else {
      navigate('/mentor/evaluation')
      alert(message);
    }
  }

  if (isLoading) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="h5">Penilaian</h1>
          <div className="col-2">
            <div className="d-grid gap-2">
              <button onClick={() => onHandleDeleteStudents()} className='btn btn-danger'>Hapus</button>
            </div>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-12">
            <label htmlFor="Mentors" className="form-label">Mentors</label>
            <div className="input-group has-validation">
              <input disabled value={`${data?.mentors?.firstName} ${data?.mentors?.lastName}`} type="text" className="form-control" id="Mentors" placeholder="Mentors" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Mentess" className="form-label">Mentess</label>
            <div className="input-group has-validation">
              <input disabled value={`${data?.mentessFirstName} ${data?.mentessLastName}`} type="text" className="form-control" id="Mentess" placeholder="Mentess" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Jurusan" className="form-label">Jurusan</label>
            <div className="input-group has-validation">
              <input disabled value={data?.universities?.universitiesMajor} type="text" className="form-control" id="Jurusan" placeholder="Jurusan" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Universitas" className="form-label">Universitas</label>
            <div className="input-group has-validation">
              <input disabled value={data?.universities?.universitiesName} type="text" className="form-control" id="Universitas" placeholder="Universitas" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Divisi" className="form-label">Divisi</label>
            <div className="input-group has-validation">
              <input disabled value={data?.institutions?.divisionName} type="text" className="form-control" id="Divisi" placeholder="Divisi" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Catatan" className="form-label">Catatan</label>
            <div className="input-group has-validation">
              <input disabled value={data?.finalEvaluationNote} type="text" className="form-control" id="Catatan" placeholder="Catatan" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Nilai Rata-Rata" className="form-label">Nilai Rata-Rata</label>
            <div className="input-group has-validation">
              <input disabled value={data?.finalEvaluationAverage} type="text" className="form-control" id="Nilai Rata-Rata" placeholder="Nilai Rata-Rata" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Nilai Akhir" className="form-label">Nilai Akhir</label>
            <div className="input-group has-validation">
              <input disabled value={data?.finalEvaluationTotal} type="text" className="form-control" id="Nilai Akhir" placeholder="Nilai Akhir" required />
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <h1 className="h5">Penilaian Antar Teman</h1>
          </div>

          <div className="col-12">
            <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={data?.evaluatedPAT?.progress} aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar" style={{ width: data?.evaluatedPAT?.progress + '%' }}>{data?.evaluatedPAT?.progress}%</div>
            </div>
          </div>

          <div className="col-6">
            <label htmlFor="Nama" className="form-label">Sudah dinilai</label>
            <div className="input-group has-validation">
              <input disabled value={data?.evaluatedPAT?.hasEvaluated} type="text" className="form-control" id="Nama" placeholder="Sudah dinilai" />
            </div>
          </div>

          <div className="col-6">
            <label htmlFor="Nama" className="form-label">Belum dinilai</label>
            <div className="input-group has-validation">
              <input disabled value={data?.evaluatedPAT?.notEvaluated} type="text" className="form-control" id="Nama" placeholder="Belum dinilai" />
            </div>
          </div>

          <div className="col-12 table-responsive card p-3">

            <div className="d-flex justify-content-between mb-3">
              <h1 className='h5'>Bakrie Center Foundation</h1>
            </div>

            {!data?.evaluationsBCF?.classifications?.length < 1 &&
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">KPI</th>
                    <th scope="col">Nilai</th>
                    <th scope="col">Catatan</th>
                    <th scope="col">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.evaluationsBCF?.classifications?.map((value, key) => (
                    <tr key={key} style={{ cursor: 'default' }}>
                      <th scope="row">{key + 1}</th>
                      <td>{value.classification}</td>
                      <td>{value.evaluation}</td>
                      <td>{value.note}</td>
                      <td>{value.classification === 'Penilaian Antar Teman' && <button onClick={() => navigate(`/mentor/evaluation/${id}/pat`)} className='btn btn-primary badge rounded-pill'>Lihat</button>}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }

          </div>

          <div className="col-12 table-responsive card p-3">

            <div className="d-flex justify-content-between mb-3">
              <h1 className='h5'>Mentors</h1>
            </div>

            {!data?.evaluationsHS?.length < 1 ?
              <>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">KPI Hardskill</th>
                      <th scope="col">Nilai</th>
                      <th scope="col">Catatan</th>
                      <th scope="col">
                        <div className='d-flex justify-content-end'>
                          <button onClick={() => navigate(`/mentor/evaluation/${id}/evaluate/hard-skill`, { state: { data } })} className='btn btn-primary'>Nilai</button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.evaluationsHS?.map((value, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{value.classification}</td>
                        <td>{value.evaluation}</td>
                        <td colSpan={2}>{value.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
              :
              <div class="alert alert-warning alert-dismissible fade show text-center" role="alert">
                <strong>KPI Hardskill</strong> belum ditambah sesuai divisi!
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
            }
            {!data?.evaluationsSS?.length < 1 &&
              <>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">KPI Softskill</th>
                      <th scope="col">Nilai</th>
                      <th scope="col">Catatan</th>
                      <th scope="col">
                        <div className='d-flex justify-content-end'>
                          <button onClick={() => navigate(`/mentor/evaluation/${id}/evaluate/soft-skill`, { state: { data } })} className='btn btn-primary'>Nilai</button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.evaluationsSS?.map((value, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{value.classification}</td>
                        <td>{value.evaluation}</td>
                        <td colSpan={2}>{value.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            }
          </div>

          <div className="col-12 table-responsive card p-3">
            <h1 className='h5'>Total Nilai</h1>
            <table className='table'>
              {!data?.evaluatedMSIB?.length < 1 ?
                <>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nilai MSIB</th>
                      <th scope="col">Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.evaluatedMSIB?.map((value, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{value.evaluation}</td>
                        {!key > 0 &&
                          <div className='d-flex justify-content-center'>
                            <td rowSpan={5}>Akumulasi Hardskill + Softskill</td>
                          </div>
                        }
                      </tr>
                    ))}
                  </tbody>
                </>
                :
                <p>Belum dinilai!</p>
              }
            </table>
          </div>

        </div>

      </div>
    </div>
  )
}

export default EvaluationDetail;
