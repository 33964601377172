import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import api from '../utils/api';

const Sidebar = ({ authUser, signOut }) => {
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const slug = pathname[2];
  const [load, setLoad] = useState(false);
  const role = authUser.currentlyProgram.role.roleName.toLowerCase();

  console.log(role);

  const onHandleExports = async () => {
    setLoad(true);
    const data = await api.adminUsersExports({ kepulangan: 'Mengajukan Kepulangan' });
    setTimeout(() => {
      window.open(data.url);
      setLoad(false)
    }, 500);
  }

  return (
    <div class="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary shadow-sm" style={{ width: 'auto' }}>
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
        {/* <svg class="bi pe-none me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
        <span class="fs-4">{role === 'admin' ? 'Administrator' : 'Mentor'}</span>
      </a>
      <hr />
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <Link to={`/${role}`} class={`nav-link ${slug === undefined ? 'active' : 'link-body-emphasis'}`} aria-current="page">
            {/* <svg class="bi pe-none me-2" width="16" height="16"><use xlink:href="#home"></use></svg> */}
            Dashboard
          </Link>
        </li>
        {role === 'admin' &&
          <li class="nav-item">
            <Link to="/admin/publication" class={`nav-link ${slug === 'publication' ? 'active' : 'link-body-emphasis'}`} aria-current="page">
              {/* <svg class="bi pe-none me-2" width="16" height="16"><use xlink:href="#home"></use></svg> */}
              Publikasi
            </Link>
          </li>
        }
        {role === 'mentor' &&
          <li class="nav-item">
            <Link to="/mentor/registrations" class={`nav-link ${slug === 'registrations' ? 'active' : 'link-body-emphasis'}`} aria-current="page">
              {/* <svg class="bi pe-none me-2" width="16" height="16"><use xlink:href="#home"></use></svg> */}
              Pendaftaran
            </Link>
          </li>
        }
        <li class="nav-item">
          <a href="/#" data-bs-toggle="dropdown" class="dropdown-toggle nav-link link-body-emphasis">
            Penilaian
          </a>
          <ul class="dropdown-menu text-small shadow">
            <li className='nav-item'>
              <Link to={`/${role}/evaluation`} class={`nav-link ${slug === 'evaluation' ? 'active' : 'link-body-emphasis'}`}>
                Mahasiswa
              </Link>
              <Link to={`/${role}/classifications`} class={`nav-link ${slug === 'classifications' ? 'active' : 'link-body-emphasis'}`}>
                KPI
              </Link>
            </li>
          </ul>
        </li>
        {role === 'admin' &&
          <>
            <li className="nav-item dropdown">
              <a href="/#" data-bs-toggle="dropdown" class="dropdown-toggle nav-link link-body-emphasis">
                Pengguna
              </a>
              <ul class="dropdown-menu text-small shadow">
                <li className='nav-item'>
                  <Link to="/admin/users" class={`nav-link ${slug === 'users' ? 'active' : 'link-body-emphasis'}`}>
                    Mahasiswa
                  </Link>
                  <Link to="/admin/mentors" class={`nav-link ${slug === 'mentors' ? 'active' : 'link-body-emphasis'}`}>
                    Mentor
                  </Link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <Link to={`/${role}/institutions`} class={`nav-link ${slug === 'institutions' ? 'active' : 'link-body-emphasis'}`} aria-current="page">
                Lembaga
              </Link>
            </li>

            <li className='nav-item dropdown'>
              <a href="/#" data-bs-toggle="dropdown" class="dropdown-toggle nav-link link-body-emphasis">
                Exports
              </a>
              <ul class="dropdown-menu text-small shadow">
                <li className='nav-item'>
                  <button disabled={load} onClick={() => onHandleExports()} type='button' class="dropdown-item">
                    {load && <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                    <span role="status"> Mengajukan Kepulangan</span>
                  </button>
                </li>
              </ul>
            </li>
          </>
        }
      </ul>
      <hr />
      <div class="dropdown">
        <a href="#/" class="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          {role === 'admin' &&
            <img src="https://i0.wp.com/bcf.or.id/file/uploads/2023/10/Rani.jpg?resize=150%2C150&ssl=1" style={{ width: 50, height: 50, objectFit: 'scale-down' }} alt="" width="32" height="32" class="rounded-circle me-2" />
          }
          <strong>{authUser?.firstName} {authUser?.lastName}</strong>
        </a>
        <ul class="dropdown-menu text-small shadow">
          <li><a class="dropdown-item" href="#/">Profile</a></li>
          <li><hr class="dropdown-divider" /></li>
          <li><a onClick={signOut} class="dropdown-item" href="#/">Sign out</a></li>
        </ul>
      </div>
    </div >
  )
}

export default Sidebar