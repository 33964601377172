import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../../utils/api';

const UsersDetail = () => {
  const location = useLocation();

  const path = location.pathname.split('/');
  const id = path[3];

  const [userData, setData] = useState();

  useEffect(() => {
    const getUsersById = async () => {
      const data = await api.adminGetUsersById({ id });
      setData(data);
      console.log(data);
    }

    getUsersById();
  }, [id]);

  return (
    <div className="row g-3">

      <div className='card'>
        <div className='card-body'>

          <h4 className="mb-3">Data Personal</h4>

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="username" className="form-label">ID Keg [ID Kampus Merdeka]</label>
              <div className="input-group has-validation">
                <input disabled value={userData?.kegId} type="text" className="form-control" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="username" className="form-label">NIM</label>
              <div className="input-group has-validation">
                <input disabled value={userData?.universities.nim} type="text" className="form-control" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="username" className="form-label">Nama</label>
              <div className="input-group has-validation">
                <input disabled value={`${userData?.firstName} ${userData?.lastName}`} type="text" className="form-control" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="email" className="form-label">Email</label>
              <input disabled value={userData?.email} type="email" className="form-control" id="email" placeholder="you@example.com" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">No HP</label>
              <input disabled value={userData?.numberPhone} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Tanggal Lahir</label>
              <input disabled value={userData?.birthDate} type="date" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="username" className="form-label">Nomor KTP</label>
              <div className="input-group has-validation">
                <input disabled value={userData?.numberCardId} type="text" className="form-control" />
              </div>
            </div>

            {userData?.domicile?.map((value, key) => (
              <>
                <div className="col-6" key={key}>
                  <label htmlFor="country" className="form-label">Kota Domisili {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}</label>
                  <input disabled value={value?.regenciesName} type="text" className="form-control" />
                </div>

                <div className="col-6" key={key}>
                  <label htmlFor="country" className="form-label">Provinsi Domisili {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}</label>
                  <input disabled value={value?.provinceName} type="text" className="form-control" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">
                    Alamat Lengkap Mahasiswa (Berdasarkan {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress})
                  </label>
                  <input disabled value={value?.completeAddress} type="text" className="form-control" />
                </div>
              </>
            ))}
          </div>

        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Rekening</h4>

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="address" className="form-label">Nomor Rekening Mahasiswa</label>
              <input disabled value={userData?.bankAccountNumber} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Nama Lengkap Pemilik Rekening (Nama Sesuai Buku Rekening)</label>
              <input disabled value={userData?.bankAccountName} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Nama Bank</label>
              <input disabled value={userData?.bankName} type="text" className="form-control" />
            </div>
          </div>

        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Kontak Darurat</h4>

          <div className="row g-3">
            <div className="col-6">
              <label htmlFor="address" className="form-label">Nomor HP Darurat</label>
              <input disabled value={userData?.emergencyPhoneNumber} type="text" className="form-control" />
            </div>

            <div className="col-6">
              <label htmlFor="address" className="form-label">Hubungan Nomor HP Darurat</label>
              <input disabled value={userData?.emergencyPhoneNumberRelationship} type="text" className="form-control" />
            </div>
          </div>

        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Universitas</h4>

          <div className="row g-3">

            <div className="col-12">
              <label htmlFor="address" className="form-label">Asal Universitas</label>
              <input disabled value={userData?.universities?.universitiesDetail.universitiesName} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi Domisili Universitas</label>
              <input disabled value={userData?.universities?.universitiesDetail.universitiesProvinces} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Kota Domisili Universitas</label>
              <input disabled value={userData?.universities?.universitiesDetail.universitiesRegencies} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Program Studi</label>
              <input disabled value={userData?.universities?.universitiesDetail.universitiesMajor} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Jenjang Pendidikan</label>
              <input disabled value={userData?.universities?.educationLevel} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Tahun Angkatan Kuliah</label>
              <input disabled value={userData?.universities?.collageYear} type="text" className="form-control" />
            </div>

          </div>

        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Program</h4>

          <div className="row g-3">
            <div className="col-6">
              <label htmlFor="address" className="form-label">Program</label>
              <input disabled value={userData?.thirdTierProgramName} type="text" className="form-control" />
            </div>

            <div className="col-6">
              <label htmlFor="address" className="form-label">Cluster</label>
              <input disabled value={userData?.institutions?.institutionsClusterFocus} type="text" className="form-control" />
            </div>

            <div className="col-6">
              <label htmlFor="address" className="form-label">Provinsi Penempatan</label>
              <input disabled value={userData?.institutions?.institutionsLocation} type="text" className="form-control" />
            </div>

            <div className="col-6">
              <label htmlFor="address" className="form-label">Kab/Kota Penempatan</label>
              <input disabled value={userData?.regenciesUsersLocation} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Lembaga Mitra Penempatan Magang</label>
              <input disabled value={userData?.institutions?.institutionsName} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Lembaga Divisi/Bidang</label>
              <input disabled value={userData?.institutions?.divisionName} type="text" className="form-control" />
            </div>
          </div>

        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Keberangkatan</h4>

          <div className="row g-3">

            <div className="col-12">
              <label htmlFor="address" className="form-label">Pengajuan Mobilisasi Keberangkatan</label>
              <input disabled value={userData?.mob_keb_pengajuan_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi keberangkatan</label>
              <input disabled value={userData?.mob_keb_provinsi_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Moda Keberangkatan</label>
              <input disabled value={userData?.mob_keb_moda_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Domisili Provinsi Asal Keberangkatan</label>
              <input disabled value={userData?.mob_keb_provinsi_asal_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Bandara / Stasiun Keberangkatan</label>
              <input disabled value={userData?.mob_keb_bandara_stasiun_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Keberangkatan</label>
              <input disabled value={userData?.mob_keb_prov_bandara_stasiun_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Bandara / Stasiun Tujuan</label>
              <input disabled value={userData?.mob_keb_bandara_stasiun_tujuan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Tujuan</label>
              <input disabled value={userData?.mob_keb_prov_bandara_stasiun_tujuan} type="text" className="form-control" id="address" />
            </div>

          </div>

        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Kepulangan</h4>

          <div className="row g-3">

            <div className="col-12">
              <label htmlFor="address" className="form-label">Pengajuan Mobilisasi Kepulangan</label>
              <input disabled value={userData?.mob_kep_pengajuan_kepulangan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi Kepulangan</label>
              <input disabled value={userData?.mob_kep_provinsi_kepulangan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Moda Kepulangan</label>
              <input disabled value={userData?.mob_kep_moda_kepulangan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Domisili Provinsi Asal Keberangkatan (Menuju Lokasi Pulang)</label>
              <input disabled value={userData?.mob_kep_provinsi_asal_keberangkatan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Bandara / Stasiun Kepulangan</label>
              <input disabled value={userData?.mob_kep_bandara_stasiun_kepulangan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Kepulangan</label>
              <input disabled value={userData?.mob_kep_prov_bandara_stasiun_kepulangan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Bandara / Stasiun Tujuan</label>
              <input disabled value={userData?.mob_kep_bandara_stasiun_tujuan} type="text" className="form-control" id="address" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Tujuan</label>
              <input disabled value={userData?.mob_kep_prov_bandara_stasiun_tujuan} type="text" className="form-control" id="address" />
            </div>

          </div>

        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="col-12">
            <label htmlFor="country" className="form-label">Surat Keterangan (Google Drive Link)</label>
            <input disabled value={userData?.mob_url_surat_keterangan} type="text" className="form-control" id="address" />
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h4 className="mb-3">Minat Dan Bakat</h4>

          <div className="row g-3">

            <div className="col-12">
              <label htmlFor="address" className="form-label">Kemampuan Apa yang Kamu Miliki?</label>
              <textarea disabled value={userData?.ability} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Dekripsikan Pengalaman terkait Bakat/Kemampuan yang Kamu Miliki</label>
              <textarea disabled value={userData?.abilityDescription} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Alat Musik Apa yang Dapat Kamu Mainkan?</label>
              <textarea disabled value={userData?.musicalInstrument} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Dalam kurun waktu 5 tahun terakhir, apakah kamu pernah mendapatkan penghargaan yang berkaitan dengan Bakat/Kemampuan yang kamu miliki?</label>
              <textarea disabled value={userData?.fiveYearAward} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Kegiatan-kegiatan BCF Mana Saja yang Kamu Minati?</label>
              <textarea disabled value={userData?.activitiesBCF} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Apakah kamu memiliki kesibukan lain saat ini? Silakan sebutkan dan jelaskan secara singkat.</label>
              <textarea disabled value={userData?.activitiesOthers} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Apakah rencanamu dalam waku 5 tahun ke depan, dalam aspek karir maupun pendidikan?</label>
              <textarea disabled value={userData?.fiveYearPlan} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Ceritakan secara singkat mengenai rencanamu ke depan sesuai dengan pilihan yang kamu isi pada pertanyaan sebelumnya.</label>
              <textarea disabled value={userData?.fiveYearPlanDescription} type="text" className="form-control" />
            </div>

          </div>

        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="col-12">
            <label htmlFor="address" className="form-label">Foto Diri Mahasiswa untuk ID Card (Google Drive Link)</label>
            <input disabled value={userData?.cardSelfieId} type="text" className="form-control" />
          </div>
        </div>

      </div>

      <div className="card">
        <div className="card-body">

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="address" className="form-label">Link Akun Instagram Mahasiswa</label>
              <input disabled value={userData?.socmedInstagram} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Link Akun LinkedIn Mahasiswa</label>
              <input disabled value={userData?.socmedLinkedIn} type="text" className="form-control" />
            </div>

            <div className="col-12">
              <label htmlFor="address" className="form-label">Link Akun TikTok Mahasiswa</label>
              <input disabled value={userData?.socmedTiktok} type="text" className="form-control" />
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default UsersDetail