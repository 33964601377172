import React, { useState, useEffect } from 'react';
import api from '../../utils/api';

const Index = () => {
  const [timeline, setTimeline] = useState([]);
  const [hasUpdate, setHasUpdate] = useState();
  const [batchId, setBatchId] = useState(2);
  const [loading, setIsLoading] = useState({
    timeline: true,
    hasUpdate: true,
  });

  useEffect(() => {
    const getTimelineByBatchId = async () => {
      const data = await api.getTimelineByBatchId({ batchId });
      setTimeline(data);
      setIsLoading({ timeline: false })
    }

    const getHasUpdated = async () => {
      const data = await api.getHasUpdatedStudent({ batchId });
      setHasUpdate(data);
      setIsLoading({ hasUpdate: false })
    }

    getTimelineByBatchId();
    getHasUpdated();
  }, [batchId]);

  return (
    <div className='row g-3'>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h1 className='h5'>Dashboard</h1>
              <div className="row">
                <div className="col-12">
                  <select value={batchId} onChange={(e) => setBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                    <option value={1}>BATCH: 8</option>
                    <option value={2}>BATCH: 9</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-6'>
        <div className="card">
          <div className="card-body">
            <h1 className='h5'>Status Update Data Mahasiswa</h1>
            {loading?.hasUpdate ?
              <>
                <div className="row g-3 placeholder-glow">
                  <div className="col-12">
                    <span className='placeholder col-12'></span>
                  </div>
                  <div className="col-6">
                    <span className='placeholder col-12'></span>
                  </div>
                  <div className="col-6">
                    <span className='placeholder col-12'></span>
                  </div>
                </div>
              </>
              :
              <>
                <div className="row g-3">
                  <div className="col-12">
                    <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={hasUpdate?.progressHasUpdate} aria-valuemin="0" aria-valuemax="100">
                      <div class="progress-bar" style={{ width: hasUpdate?.progressHasUpdate + '%' }}>{hasUpdate?.progressHasUpdate}%</div>
                    </div>
                  </div>

                  <div className="col-6">
                    <label htmlFor="Nama" className="form-label">Sudah update</label>
                    <div className="input-group has-validation">
                      <input disabled value={hasUpdate?.totalHasUpdate} type="text" className="form-control" id="Nama" placeholder="Sudah Update" />
                    </div>
                  </div>

                  <div className="col-6">
                    <label htmlFor="Nama" className="form-label">Belum update</label>
                    <div className="input-group has-validation">
                      <input disabled value={hasUpdate?.totalNotUpdate} type="text" className="form-control" id="Nama" placeholder="Nama" />
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>

      <div className="col-6">
        <div className="card">
          <div className="card-body">
            <h1 className='h5'>Timeline Magang CLP 9</h1>
            {loading?.timeline ?
              <>
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
              :
              <>
                <div class="list-group">
                  {timeline.map((value, key) => (
                    <label class={`list-group-item d-flex gap-3 py-3 ${!value.activeStatus.status && 'bg-body-tertiary'}`} key={key}>
                      <div className="d-flex gap-2 w-100 justify-content-between">
                        <span class="pt-1 form-checked-content">
                          <strong>{value.title}</strong>
                          <small class="d-block text-body-secondary">
                            {value.startDate} - {value.endDate}
                          </small>
                        </span>
                        <div className="d-flex align-items-center">
                          <span class={`badge rounded-pill ${value.activeStatus.status ? 'text-bg-primary' : 'text-bg-success'}`}>
                            {value.activeStatus.message}
                          </span>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index