import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../utils/api';

const MentorsList = () => {
  const navigate = useNavigate();
  const [mentors, setMentors] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage] = useState(searchParams.get("page") || "1");
  const [pageSize] = useState(searchParams.get("pageSize") || "25");
  const [batchId, setBatchId] = useState(searchParams.get("batch") || "1");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!searchParams.has('pageSize')) {
      setSearchParams({ ...searchParams, pageSize });
      navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`);
    }

    const getMentors = async () => {
      const data = await api.adminGetMentors({ batchId, page: currentPage, pageSize });
      setMentors(data);
      setLoading(false);
    }

    getMentors();
  }, []);

  const handleNavigateToPage = (pageNumber) => {
    setSearchParams({ ...searchParams, page: pageNumber });
    navigate(`?batch=${batchId}&page=${pageNumber}&pageSize=${pageSize}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize: pageSize });
    navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`)
  }

  const handleBatchId = (batchId) => {
    setSearchParams({ ...searchParams, batch: batchId });
    navigate(`?batch=${batchId}&page=${currentPage}&pageSize=${pageSize}`)
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Mentor</h1>
          <div className="row">
            <div className="col-4">
              <select value={pageSize} onChange={(e) => handlePageSize(e.target.value)} class="form-select" aria-label="Default select example">
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="col-4">
              <select value={batchId} onChange={(e) => handleBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                <option value={1}>BATCH: 8</option>
                <option value={2}>BATCH: 9</option>
                <option value={3}>BATCH: 10</option>
              </select>
            </div>
            <div className="col-3">
              <div className="d-grid gap-2">
                <Link to='/admin/mentors/add' className='btn btn-primary'>Tambah</Link>
              </div>
            </div>
          </div>
        </div>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Jabatan</th>
              <th scope="col">Keahlian</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {Array.apply(null, Array(Number(pageSize))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {mentors?.mentors?.map((value, key) => (
                  <tr key={key} onClick={() => navigate(`/admin/mentors/${value.mentorsId}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{(currentPage - 1) * pageSize + (key + 1)}</th>
                    <td>{`${value.firstName} ${value.lastName}`}</td>
                    <td>{value.position}</td>
                    <td>{value.skill}</td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(mentors.previous)} class={`page-link ${!mentors.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{mentors.current + 1 || parseInt(currentPage)}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(mentors.next + 1)} class={`page-link ${!mentors.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default MentorsList