import React, { useEffect, useState } from 'react';
import api from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

const UpdateDiri = ({ authUser }) => {
  const { batchId = 1 } = authUser?.currentlyProgram.batch || {};
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [provinces, setProvinces] = useState([]);
  const [regencies, setRegencies] = useState([]);

  useEffect(() => {
    const onHandlerGetOwnFullProfile = async () => {
      const data = await api.getOwnFullProfile({ batchId });
      setProfile(data);
    }

    const onHandlerGetProvincies = async () => {
      const data = await api.getProvincies();
      setProvinces({
        0: data, // DOMICILE
        1: data, // DOMICILE
        2: data, // UNIVERSITIES
      });;
    }

    const onHandlePreloadGetRegencies = async () => {
      profile?.domicile?.map(async (e, key) => {
        const lock = await mutex.acquire();
        try {
          const data = await api.getRegenciesByProvincies({ id: e.provinceId });
          setRegencies({
            ...regencies,
            [key]: data,
          });
          console.log(`LOG: ${key}`)
        } finally {
          lock();
          console.log(regencies);
        }
      });
    }

    onHandlerGetOwnFullProfile();
    onHandlerGetProvincies();
    onHandlePreloadGetRegencies();
  }, []);

  const onHandleRegenciesByPronvicies = async ({ e, key }) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const provinceId = el.getAttribute('id');
    const provinceName = el.getAttribute('value');
    const data = await api.getRegenciesByProvincies({ id: provinceId });
    setRegencies({
      ...regencies,
      [key]: data,
    });
    const newState = { ...profile };
    newState.domicile[key].provinceId = provinceId;
    newState.domicile[key].provinceName = provinceName;
    setProfile(newState);
  }

  const onHandleChangeRegencies = ({ e, key }) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const regenciesId = el.getAttribute('id');
    const regenciesName = el.getAttribute('value');
    const newState = { ...profile };
    newState.domicile[key].regenciesId = regenciesId;
    newState.domicile[key].regenciesName = regenciesName;
    setProfile(newState);
  }

  const onHandleChangeCompleteAddress = ({ e, key }) => {
    const completeAddress = e.target.value;
    const newState = { ...profile };
    newState.domicile[key].completeAddress = completeAddress;
    setProfile(newState);
  }

  const onHandleChangeProvinciesUniversities = async ({ e }) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const provinceId = el.getAttribute('id');
    const provinceName = el.getAttribute('value');
    const data = await api.getRegenciesByProvincies({ id: provinceId });
    setRegencies({
      ...regencies,
      2: data,
    });
    setProfile({
      ...profile,
      universities: {
        ...profile.universities,
        universitiesDetail: {
          ...profile.universities.universitiesDetail,
          universitiesProvinces: provinceName,
          universitiesProvincesId: provinceId,
        }
      }
    });
  }

  const onHandleChangeRegenciesUniversities = ({ e }) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const regenciesId = el.getAttribute('id');
    const regenciesName = el.getAttribute('value');
    const newState = { ...profile };
    newState.universities.universitiesDetail.universitiesRegenciesId = regenciesId;
    newState.universities.universitiesDetail.universitiesRegencies = regenciesName;
    setProfile(newState);
  }

  const onHandleGetRegenciesProgramLocation = async ({ e, provinciesId }) => {
    const data = await api.getRegenciesByProvincies({ id: provinciesId });
    setRegencies({
      ...regencies,
      3: data,
    });
  }

  const onHandleChangeRegenciesProgramLocation = async ({ e }) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const regenciesId = el.getAttribute('id');
    const regenciesName = el.getAttribute('value');
    const newState = { ...profile };
    newState.regenciesUsersLocation = regenciesName;
    newState.regenciesUsersLocationId = parseInt(regenciesId);
    setProfile(newState);
  }

  const onHandleSubmit = () => {
    const {
      tgl_lahir,
      nomor_ktp,
      provinsi_domisili_ktp,
      kota_domisili_ktp,
      provinsi_domisili_now,
      kota_domisili_now,
      alamat_lengkap_ktp,
      alamat_lengkap_now,
      nomor_rekening,
      nama_rekening,
      nama_bank,
      nomor_hp_darurat,
      hubungan_nomor_hp_darurat,
      provinsi_domisili_universitas,
      kota_domisili_universitas,
      program_studi,
      tahun_angkatan_kuliah,
    } = profile;

    if (tgl_lahir === "") return alert('Tgl Lahir Kosong!')
    if (nomor_ktp === "") return alert('Nomor KTP Kosong!')
    if (provinsi_domisili_ktp === "") return alert('Provinsi Domisili KTP Kosong!')
    if (kota_domisili_ktp === "") return alert('Kota Domisili KTP Kosong!')
    if (provinsi_domisili_now === "") return alert('Provinsi Domisili Sekarang Kosong!')
    if (kota_domisili_now === "") return alert('Kota Domisili Sekarang Kosong!')
    if (alamat_lengkap_ktp === "") return alert('Alamat Lengkap Berdasarkan KTP Kosong!')
    if (alamat_lengkap_now === "") return alert('Alamat Lengkap (Tempat Tinggal Saat Ini) Kosong!')
    if (nomor_rekening === "") return alert('Nomor Rekening Kosong!')
    if (nama_rekening === "") return alert('Nama Rekening Kosong!')
    if (nama_bank === "") return alert('Nama Bank Kosong!')
    if (nomor_hp_darurat === "") return alert('Nomor HP Darurat Kosong!')
    if (hubungan_nomor_hp_darurat === "") return alert('Hubungan Nomor HP Darurat Kosong!')
    if (provinsi_domisili_universitas === "") return alert('Provinsi Domisili Universitas Kosong!')
    if (kota_domisili_universitas === "") return alert('Kota Domisili Universitas Kosong!')
    if (program_studi === "") return alert('Program Studi Kosong!')
    if (tahun_angkatan_kuliah === "") return alert('Tahun Angkatan Kuliah Kosong!')

    navigate('/app/profile/update/mobilisasi', { state: { user: profile } })
  }

  return (
    <>
      <div className="py-5 text-center">
        <h2>I. DATA DIRI</h2>
        <p className="lead">Isi bagian identitas mahasiswa dengan benar tanpa singkatan
          Perhatikan ketentuan dan syarat dari setiap pertanyaan yang diajukan</p>
      </div>

      <div className="row g-5">

        <div className="col-md-12 col-lg-8">
          <form className="needs-validation mb-5" noValidate>
            <div className="row g-3">

              <div className='card'>
                <div className='card-body'>
                  <h4 className="mb-3">Data Personal</h4>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="username" className="form-label">NIM</label>
                      <div className="input-group has-validation">
                        <input disabled value={profile?.universities?.nim} type="text" className="form-control" required />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">Nama</label>
                      <div className="input-group has-validation">
                        <input disabled value={`${profile?.firstName} ${profile?.lastName}`} type="text" className="form-control" />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="jenis_kelamin" className="form-label">Jenis Kelamin *</label>
                      <select value={profile?.sex} onChange={(e) => setProfile({ ...profile, sex: e.target.value })} className="form-select" id="jenis_kelamin" required>
                        <option defaultValue>Pilih Jenis Kelamin</option>
                        <option value="Laki Laki">Laki Laki</option>
                        <option value="Perempuan">Perempuan</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <label htmlFor="email" className="form-label">Email <span className="text-body-secondary"><b><small>Verified</small></b></span></label>
                      <input disabled value={profile?.email} type="email" className="form-control" id="email" placeholder="you@example.com" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">No HP</label>
                      <input disabled value={profile?.numberPhone} type="text" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Tanggal Lahir *</label>
                      <input onChange={(e) => setProfile({ ...profile, birthDate: e.target.value })} value={profile?.birthDate} type="date" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">Nomor KTP *</label>
                      <div className="input-group has-validation">
                        <input onChange={(e) => setProfile({ ...profile, numberCardId: e.target.value })} value={profile?.numberCardId} type="text" className="form-control" />
                      </div>
                    </div>

                    {profile?.domicile?.map((value, key) => (
                      <>
                        <div className="col-6" key={key}>
                          <label className="form-label">Provinsi Domisili {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress} *</label>
                          <select value={value?.provinceName} onChange={(e) => onHandleRegenciesByPronvicies({ e, key })} className="form-select" >
                            <option defaultValue>Pilih Provinsi</option>
                            {provinces[key]?.map((value, key) => (
                              <option key={key} id={value.provinceId} value={value.provinceName}>{value.provinceName}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-6">
                          <label className="form-label">Kota Domisili {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress} *</label>
                          <select disabled={regencies[key]?.length <= 1} value={value?.regenciesName} onChange={(e) => onHandleChangeRegencies({ e, key })} className="form-select" >
                            <option defaultValue>Pilih Kota</option>
                            {regencies[key]?.map((value, key) => (
                              <option key={key} id={value.regenciesId} value={value.regenciesName}>{value.regenciesName}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12">
                          <label htmlFor="address" className="form-label">Alamat Lengkap Mahasiswa (Berdasarkan {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}) *</label>
                          <input onChange={(e) => onHandleChangeCompleteAddress({ e, key })} value={value.completeAddress} type="text" className="form-control" />
                        </div>
                      </>
                    ))}
                  </div>

                </div>
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h4 className="mb-3">Rekening</h4>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Nomor Rekening Mahasiswa *</label>
                      <input onChange={(e) => setProfile({ ...profile, bankAccountNumber: e.target.value })} value={profile?.bankAccountNumber} type="text" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Nama Lengkap Pemilik Rekening (Nama Sesuai Buku Rekening) *</label>
                      <input onChange={(e) => setProfile({ ...profile, bankAccountName: e.target.value })} value={profile?.bankAccountName} type="text" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label className="form-label">Nama Bank *</label>
                      <select value={profile?.bankName} onChange={(e) => setProfile({ ...profile, bankName: e.target.value })} className="form-select" >
                        <option defaultValue>Pilih Nama Bank</option>
                        <option value="BRI">BRI</option>
                        <option value="Mandiri">Mandiri</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h4 className="mb-3">Kontak Darurat</h4>

                  <div className="row g-3">
                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Nomor HP Darurat *</label>
                      <input onChange={(e) => setProfile({ ...profile, emergencyPhoneNumber: e.target.value })} value={profile?.emergencyPhoneNumber} type="text" className="form-control" />
                    </div>

                    <div className="col-6">
                      <label className="form-label">Hubungan Nomor HP Darurat *</label>
                      <select value={profile?.emergencyPhoneNumberRelationship} onChange={(e) => setProfile({ ...profile, emergencyPhoneNumberRelationship: e.target.value })} className="form-select" >
                        <option defaultValue>Pilih Hubungan Nomor HP Darurat</option>
                        <option value="Ayah">Ayah</option>
                        <option value="Ibu">Ibu</option>
                        <option value="Kakak">Kakak</option>
                        <option value="Adik">Adik</option>
                        <option value="Kakek">Kakek</option>
                        <option value="Nenek">Nenek</option>
                        <option value="Paman">Paman</option>
                        <option value="Tante/Bibi">Tante/Bibi</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h4 className="mb-3">Universitas</h4>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Asal Universitas</label>
                      <input disabled value={profile?.universities?.universitiesDetail?.universitiesName} type="text" className="form-control" />
                    </div>

                    {profile?.universities?.universitiesDetail?.universitiesProvinces === ""
                      || profile?.universities?.universitiesDetail?.universitiesRegencies === "" ?
                      <>
                        <div className="col-6">
                          <label className="form-label">Provinsi Domisili Universitas</label>
                          <select value={profile?.universities?.universitiesDetail?.universitiesProvinces} onChange={(e) => onHandleChangeProvinciesUniversities({ e })} className="form-select" >
                            <option defaultValue>Pilih Provinsi Domisili Universitas</option>
                            {provinces[2]?.map((value, key) => (
                              <option key={key} id={value.provinceId} value={value.provinceName}>{value.provinceName}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-6">
                          <label className="form-label">Kota Domisili Universitas</label>
                          <select disabled={regencies[2]?.length <= 1} value={profile?.universities?.universitiesDetail?.universitiesRegencies} onChange={(e) => onHandleChangeRegenciesUniversities({ e })} className="form-select" >
                            <option defaultValue>Pilih Kota Domisili Universitas</option>
                            {regencies[2]?.map((value, key) => (
                              <option key={key} id={value.regenciesId} value={value.regenciesName}>{value.regenciesName}</option>
                            ))}
                          </select>
                        </div>
                      </>
                      :
                      <>
                        <div className="col-6">
                          <label htmlFor="address" className="form-label">Provinsi Domisili Universitas</label>
                          <input disabled value={profile?.universities?.universitiesDetail?.universitiesProvinces} type="text" className="form-control" />
                        </div>

                        <div className="col-6">
                          <label htmlFor="address" className="form-label">Kota Domisili Universitas</label>
                          <input disabled value={profile?.universities?.universitiesDetail?.universitiesRegencies} type="text" className="form-control" />
                        </div>
                      </>
                    }

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Program Studi</label>
                      <input disabled value={profile?.universities?.universitiesDetail.universitiesMajor} type="text" className="form-control" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="jenjang_pendidikan" className="form-label">Jenjang Pendidikan *</label>
                      <select value={profile?.universities?.educationLevel} onChange={(e) => setProfile({
                        ...profile,
                        universities: {
                          ...profile.universities,
                          educationLevel: e.target.value,
                        }
                      })} className="form-select" id="jenjang_pendidikan" required>
                        <option defaultValue>Pilih Jenjang Pendidikan</option>
                        <option value="D3">D3</option>
                        <option value="D4">D4</option>
                        <option value="S1">S1</option>
                      </select>
                    </div>

                    <div className="col-6">
                      <label className="form-label">Tahun Angkatan Kuliah *</label>
                      <select value={profile?.universities?.collageYear} onChange={(e) => setProfile({
                        ...profile,
                        universities: {
                          ...profile.universities,
                          collageYear: e.target.value,
                        }
                      })} className="form-select" >
                        <option defaultValue>Pilih Tahun Angkatan Kuliah</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                      </select>
                    </div>

                  </div>

                </div>
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h4 className="mb-3">Program</h4>

                  <div className="row g-3">
                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Program</label>
                      <input disabled value={profile?.thirdTierProgramName} type="text" className="form-control" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Cluster</label>
                      <input disabled value={profile?.institutions?.institutionsClusterFocus} type="text" className="form-control" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Penempatan</label>
                      <input disabled value={profile?.institutions?.institutionsLocation} type="text" className="form-control" />
                    </div>

                    <div className="col-6">
                      <label className="form-label">Kota/Kabupaten Penempatan *</label>
                      <select disabled={regencies[3]?.length <= 1}
                        value={profile?.regenciesUsersLocation}
                        onClick={(e) => onHandleGetRegenciesProgramLocation({ e, provinciesId: profile?.institutions?.institutionsPronvinciesId })}
                        onChange={(e) => onHandleChangeRegenciesProgramLocation({ e })}
                        className="form-select" >
                        <option defaultValue>Pilih Kota/Kabupaten</option>
                        {regencies[3]?.map((value, key) => (
                          <option key={key} id={value.regenciesId} value={value.regenciesName}>{value.regenciesName}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Lembaga Mitra Penempatan Magang</label>
                      <input disabled value={profile?.institutions?.institutionsName} type="text" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Lembaga Divisi/Bidang</label>
                      <input disabled value={profile?.institutions?.divisionName} type="text" className="form-control" />
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <button disabled={!profile} onClick={() => onHandleSubmit()} className="w-100 btn btn-primary btn-lg my-4" type="button">Lanjut</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default UpdateDiri;
